import React from 'react'

export default function Card(props) {
	const { demoUrl, title, image, description } = props;

	return (
		<div className="card">
			<h4>{title}</h4>
			<div className="image-wrapper"><img src={image} /></div>
			<div className="description-wrapper">{description}</div>
			<a href={demoUrl}>View Demo</a>

		</div>
	)
}
