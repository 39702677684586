import React from 'react'
import Links from './Links';
import Card from './Card';

import '../scss/main.scss';
import Woman from '../images/woman@2x.png';
import OticonImage from '../images/oticon-portfolio-tool.jpg';
import ReactSPADemo from '../images/react-single-page-app-demo.jpg'

export default function App() {
	return (
		<div id="wrapper">
			<div id="content-wrapper">
				<h1>Elizabeth Chow</h1>
				<h2>Frontend Developer</h2>
				{/* <div id="intro-wrapper">This is the intro copy</div> */}
				<div id="recent-works-wrapper">
					<Card
						demoUrl="https://gifted-aryabhata-fce5ce.netlify.app/"
						title="React Single Page Marketing App"
						image={ReactSPADemo}
						description="React single page app developed primarily for the iPad, while also made to scale for larger screens on Windows."
						/>
					<Card
						demoUrl="https://awesome-yonath-045f1b.netlify.app/"
						title="Single Page Marketing App"
						image={OticonImage}
						description="Single page app developed primarily for the iPad, while also made to scale for larger screens on Windows."
						/>
				</div>
				<Links />
				<div id="woman">
					<img src={Woman} alt="" />
				</div>
			</div>
		</div>
	)
}
