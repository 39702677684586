import React from "react";
import BookDead from "../images/book-dead-solid.svg";
import GitHub from "../images/github-alt-brands.svg";
import Instagram from "../images/instagram-brands.svg";
import LinkedIn from "../images/linkedin-brands.svg";
import CodePen from "../images/codepen-brands.svg";
import FaIcons from "../images/icons-solid.svg";
import FileDownload from "../images/file-download-solid.svg";
import Envelope from "../images/envelope-open-text-solid.svg";
import Hands from "../images/hands-helping.svg";

export default function Links() {
  return (
    <section className="links">
      <ul>
        <li>
          <Hands />
          <a href="https://averygoodplan.com">
            <div className="label">A Very Good Plan</div>
            <div className="desc">a daily planner I produced</div>
          </a>
        </li>
        <li>
          <BookDead />
          <a href="https://links.elizabethchow.dev/">Link Tomb</a>
        </li>
        <li>
          <GitHub />
          <a href="https://github.com/elizabethchow">GitHub</a>
        </li>
        <li>
          <Instagram />
          <a href="https://instagram.com/thiselizabethchow">Instagram</a>
        </li>
        <li>
          <LinkedIn />
          <a href="https://www.linkedin.com/in/thiselizabethchow/">LinkedIn</a>
        </li>
        <li>
          <CodePen />
          <a href="https://codepen.io/elizabethchow">CodePen</a>
        </li>
        <li>
          <FaIcons />
          <a href="https://brightersidestudio.com">Brighter Side Studio</a>
        </li>
        <li>
          <FileDownload />
          <a href="./pdf/Elizabeth Chow - Portfolio Resume.pdf">
            Download My Resume
          </a>
        </li>
        <li className="email-link">
          <Envelope />
          <span>hello@elizabethchow.dev</span>
        </li>
      </ul>
    </section>
  );
}
